import { Attendee } from "../types/interfaces";

export const InitAttendee: Attendee = {
  firstName: "",
  lastName: "",
  age: "",
  memberType: "",
  active: 1,
  id: 0,
};
